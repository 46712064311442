<template>
  <v-simple-table v-if="contadores">
    <template v-slot:default>
      <tbody>
        <tr
          id="linhas-importadas"
          :style="{ color: contadores.linhasImportadas ? 'green' : 'red' }"
        >
          <td style="width: 50%">(1) Linhas inseridas ou atualizadas:</td>
          <td>
            {{ contadores.linhasImportadas | parseNumberToIntegerBR }} ({{
              percLinhasImportadas | parseNumberToFloatBR
            }}%)
          </td>
        </tr>
        <tr
          id="linhas-nao-importadas"
          :style="{ color: contadores.linhasNaoImportadas ? 'red' : 'green' }"
        >
          <td>(2) Linhas não inseridas ou atualizadas por erro:</td>
          <td>
            {{ contadores.linhasNaoImportadas | parseNumberToIntegerBR }} ({{
              percLinhasNaoImportadas | parseNumberToFloatBR
            }}%)
          </td>
        </tr>
        <tr
          id="linhas-processadas"
          :style="{ color: linhasProcessadas ? 'green' : 'red' }"
        >
          <td>(3) Linhas processadas (1) + (2):</td>
          <td>
            {{ linhasProcessadas | parseNumberToIntegerBR }} ({{
              percLinhasProcessadas | parseNumberToFloatBR
            }}%)
          </td>
        </tr>
        <tr
          id="linhas-importaveis"
          :style="{ color: contadores.linhasImportaveis ? 'green' : 'red' }"
        >
          <td>(4) Linhas importáveis:</td>
          <td>
            {{ contadores.linhasImportaveis | parseNumberToIntegerBR }}
          </td>
        </tr>
        <tr
          id="linhas-nao-importaveis"
          :style="{
            color: contadores.linhasNaoImportaveis ? '#FF6D00' : 'green'
          }"
        >
          <td>(5) Linhas não importáveis:</td>
          <td>
            {{ contadores.linhasNaoImportaveis | parseNumberToIntegerBR }}
          </td>
        </tr>
        <tr
          id="linhas-em-branco"
          :style="{
            color: contadores.linhasEmBranco ? '#FF6D00' : 'green'
          }"
        >
          <td>(6) Linhas em branco:</td>
          <td>
            {{ contadores.linhasEmBranco | parseNumberToIntegerBR }}
          </td>
        </tr>
        <tr
          id="linhas-cabecalhos"
          :style="{
            color: !contadores.linhasCabecalhos ? '#FF6D00' : 'green'
          }"
        >
          <td>(7) Linhas cabeçalhos:</td>
          <td>
            {{ contadores.linhasCabecalhos | parseNumberToIntegerBR }}
          </td>
        </tr>
        <tr
          id="numero-linhas"
          :style="{ color: contadores.numeroLinhas ? 'green' : 'red' }"
        >
          <td>(8) Total de linhas no arquivo (4) + (5) + (6) + (7):</td>
          <td>
            {{ contadores.numeroLinhas | parseNumberToIntegerBR }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TabelaContadores',
  props: {
    contadores: {
      type: Object
    }
  },
  computed: {
    percLinhasImportadas() {
      let percLinhasImportadas = 0;
      let { linhasImportaveis, numeroLinhas, linhasImportadas } =
        this.contadores;
      let totalLinhas = linhasImportaveis ? linhasImportaveis : numeroLinhas;

      if (linhasImportadas && totalLinhas) {
        percLinhasImportadas = linhasImportadas / totalLinhas;
      }

      return (percLinhasImportadas * 100).toFixed(2);
    },
    percLinhasNaoImportadas() {
      let percLinhasNaoImportadas = 0;
      let { linhasImportaveis, numeroLinhas, linhasNaoImportadas } =
        this.contadores;
      let totalLinhas = linhasImportaveis ? linhasImportaveis : numeroLinhas;

      if (linhasNaoImportadas && totalLinhas) {
        percLinhasNaoImportadas = linhasNaoImportadas / totalLinhas;
      }

      return (percLinhasNaoImportadas * 100).toFixed(2);
    },
    linhasProcessadas() {
      let { linhasImportadas, linhasNaoImportadas } = this.contadores;
      return linhasImportadas + linhasNaoImportadas;
    },
    percLinhasProcessadas() {
      return (
        Number(this.percLinhasImportadas) + Number(this.percLinhasNaoImportadas)
      ).toFixed(2);
    }
  }
};
</script>
